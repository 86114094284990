@tailwind base;
@tailwind components;
@tailwind utilities;

// @import './customStyles/shadows.css';
// @import './customStyles/buttons.scss';
// @import './customStyles/animations.css';

@layer base {
  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins/Poppins-Light.ttf') format('woff2');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins/Poppins-Regular.ttf') format('woff2');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins/Poppins-Medium.ttf') format('woff2');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins/Poppins-SemiBold.ttf') format('woff2');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins/Poppins-Bold.ttf') format('woff2');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins/Poppins-ExtraBold.ttf') format('woff2');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-Bold.ttf') format('woff2');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-Medium.ttf') format('woff2');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('woff2');
    font-weight: 400;
    font-style: normal;
  }
}

body {
  @apply bg-background-primary text-white;
  @apply font-serif;
}

@layer utilities {
  .link-shadow {
    text-shadow: 0px 2px 20px rgba(255, 255, 255, 0.5);
  }

  .gradient-border {
    position: relative;
    border-radius: 8px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: inherit;
      padding: 1.2px; /* Match the border width */
      background: linear-gradient(180deg, #00f0ff 0%, #005459 100%);
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }
}
